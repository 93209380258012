/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.min.css";

@font-face {
  font-family: "Parisienne";
  src: url("assets/fonts/Parisienne.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto.ttf");
}

@font-face {
  font-family: "Nunito";
  src: url("assets/fonts/Nunito.ttf");
}

body {
  font-family: "Nunito";
}

.raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.card-image {
  height: 158px;
  display: flex;
  align-items: center;
}
